import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container  } from 'react-bootstrap'

import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import AudioPlayer from 'react-h5-audio-player';

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <Layout>
      <Seo title={post.frontmatter.title} />
      <Container>
      <h1 className="text-center my-4 mt-5">{post.frontmatter.title}</h1>
      {post.frontmatter.image01 ? 
            (
              <p className="text-center">
                <GatsbyImage image={getImage(post.frontmatter.image01)} className="mb-3" alt={post.frontmatter.image01alt} />
              </p>
            ) : 
            null
          }
      </Container>
      <Container className="singleCol">
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image01alt
        image01 {
          childImageSharp {
            gatsbyImageData(
              height: 500
              webpOptions: {quality: 30}
              formats: [AUTO, WEBP, AVIF]
              quality: 50
              placeholder: BLURRED
              blurredOptions: {toFormat: JPG, width: 10}
              transformOptions: {cropFocus: CENTER, fit: COVER} 
            )
          }
        }
      }
    }
  }
`